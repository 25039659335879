$(document).ready(function() {

  var pathArray = window.location.pathname.split('/');
  $('.nav li').find("a[href*='"+pathArray[1]+"']").addClass('active');

  var url = window.location.href;
  var filepath = url.lastIndexOf("/") + 1;
  var matchThis = url.substr(filepath);
  $('.nav li').find("a[href*='"+matchThis+"']").addClass('active');

  // Open subnav on smaller screens
  $('.subnav').on('click', function(){
      $(this).html() == "+" ? $(this).html('–') : $(this).html('+');
      $('.sidebar').toggleClass('open');
  });

})